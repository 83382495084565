'use client';

import PropTypes from 'prop-types';
// @mui
import {Dialog, Box, Paper} from '@mui/material';
//
import {forwardRef} from "react";
import {varFade} from './variants';

// ----------------------------------------------------------------------


const DialogAnimate = forwardRef(({open = false, variants, onClose, children, sx, ...other}, ref) => (
    open && (
      <Dialog
        ref={ref}
        maxWidth="xs"
        open={open}
        onClose={onClose}
        sx={{pointerEvents: 'none'}}
        PaperComponent={(props) => (
          <Box
            {...(variants ||
              varFade({
                distance: 120,
                durationIn: 0.32,
                durationOut: 0.24,
                easeIn: 'easeInOut',
              }).inUp)}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box onClick={onClose} sx={{width: '100%', height: '100%', position: 'fixed'}}/>
            <Paper sx={sx} {...props}>
              {props.children}
            </Paper>
          </Box>
        )}
        {...other}
      >
        {children}
      </Dialog>
    )
  ));

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sx: PropTypes.object,
  variants: PropTypes.object,
};

export default DialogAnimate;
