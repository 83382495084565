import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import NextLink from 'next/link';
import { Link as MuiLink } from '@mui/material';
// @mui
//

// ----------------------------------------------------------------------

const Link = forwardRef(({ children, href, sx, ...other }, ref) => (
    <NextLink href={href} passHref legacyBehavior>
      <MuiLink {...other} sx={{ cursor: 'pointer', color: 'accent.main', ...sx }}>
        {children}
      </MuiLink>
    </NextLink>
  ),
);

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  sx: PropTypes.object,
};

export default Link;
