// icons
// @mui
import { Checkbox } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function FavoriteButton({ ...other }) {
  return (
    <Checkbox
      color="error"
      icon={<Iconify icon='carbon:favorite' sx={{ width: 20, height: 20 }} />}
      checkedIcon={<Iconify icon='carbon:favorite-filled' sx={{ width: 20, height: 20 }} />}
      {...other}
    />
  );
}
