'use client';

import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number
};

export default function Iconify({ icon, sx, width, ...other }) {
  return <Box sx={{width: width || 'auto', alignItems: 'center', display: 'flex', justifyContent: 'center'}}><Box component={Icon} icon={icon} sx={{ ...sx }} {...other} /></Box>;
}
