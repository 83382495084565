import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import { HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT } from '../../config';
import ScrollToTop from '../utils/scroll-to-top';

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, disableMargins = false, sx, disableScrollToTop = false, ...other }, ref) => (
    <>
      {!disableScrollToTop && <ScrollToTop />}
      <Box
        ref={ref}
        {...other}
        sx={{
          mt: disableMargins ? {} : { xs: HEADER_MOBILE_HEIGHT / 8, md: HEADER_DESKTOP_HEIGHT / 8 },
          ...sx,
        }}
      >
        {children}
      </Box>
    </>
  )
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  disableMargins: PropTypes.bool,
  disableScrollToTop: PropTypes.bool,
};

export default Page;
