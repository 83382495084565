'use client';

import PropTypes from 'prop-types';
import { MDXRemote } from 'next-mdx-remote';
import { Prism } from 'prism-react-renderer';
// icons
// next
import NextLink from 'next/link';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Stack, Divider, Typography } from '@mui/material';
// components
import Image from 'src/components/landing/Image';
import Iconify from 'src/components/landing/Iconify';

// ----------------------------------------------------------------------

const MARGIN = {
  marginTop: 24,
  marginBottom: 16,
};

export const RootStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'firstLetter',
})(({ firstLetter, theme }) => ({
  // Heading
  '& h1': { ...MARGIN, ...theme.typography.h1 },
  '& h2': { ...MARGIN, ...theme.typography.h2 },
  '& h3': { ...MARGIN, ...theme.typography.h3 },
  '& h4': { ...MARGIN, ...theme.typography.h4 },
  '& h5': { ...MARGIN, ...theme.typography.h5 },
  '& h6': { ...MARGIN, ...theme.typography.h6 },
  '& p': { marginBottom: theme.spacing(2) },

  // Code
  '& code': {
    color: theme.palette.common.white,
  },
  '& pre': {
    padding: theme.spacing(2, 2.5),
    margin: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 900 : 800],
    overflow: 'auto',
    '& > pre': {
      overflow: 'auto',
      padding: theme.spacing(3, 3, 0, 3),
    },
  },

  // List
  '& ul, & ol': {
    marginBottom: theme.spacing(2),
    '& li': {
      lineHeight: 2,
    },
  },

  // First Letter
  ...(firstLetter && {
    '& > p:first-of-type': {
      '&:first-letter': {
        float: 'left',
        fontSize: 80,
        lineHeight: 1,
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
}));

// ----------------------------------------------------------------------

Markdown.propTypes = {
  source: PropTypes.object.isRequired,
  firstLetter: PropTypes.bool,
};

export default function Markdown({ source, firstLetter = false }) {
  return (
    <RootStyle firstLetter={firstLetter}>
      {source && <MDXRemote {...source} components={components} />}
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

export const components = {
  p: (props) => <Typography {...props} />,
  hr: (props) => <Divider {...props} />,
  a: LinkMDX,
  blockquote: BlockquoteMDX,
  img: ImageMDX,
  // code: CodeMDX,
};

// ----------------------------------------------------------------------

LinkMDX.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

function LinkMDX({ href, children, ...other }) {
  return !href.includes('http') ? (
    <NextLink href={href} passHref legacyBehavior>
      <Link {...other} sx={{ color: 'accent.main' }}>{children}</Link>
    </NextLink>
  ) : (
    <Link {...other} href={href} target='_blank' rel='noopener' sx={{ color: 'accent.main' }}>
      {children}
    </Link>
  );
}

// ----------------------------------------------------------------------

ImageMDX.propTypes = {
  alt: PropTypes.string,
};

function ImageMDX({ alt, ...other }) {
  return <Image alt={alt} /* ratio='16/9' */ sx={{ borderRadius: 2, my: 5 }} {...other} />;
}

// ----------------------------------------------------------------------

BlockquoteMDX.propTypes = {
  children: PropTypes.node,
};

function BlockquoteMDX({ children }) {
  return (
    <Stack
      direction='row'
      spacing={{ xs: 3, md: 5 }}
      sx={{
        p: { xs: 3, md: 5 },
      }}
    >
      <Iconify
        icon='carbon:quotes'
        sx={{ width: 48, height: 48, color: 'text.disabled', opacity: 0.48 }}
      />
      <Typography variant='h5'>{children}</Typography>
    </Stack>
  );
}

// ----------------------------------------------------------------------

CodeMDX.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

function CodeMDX(props) {
  const { children, className } = props;
  const language = className?.replace(/language-/, '');
  return (
    <Prism code={children} language={language}>
      {({ style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, backgroundColor: 'transparent' }}>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Prism>
  );
}
