"use client";

import {useEffect} from "react";

export default function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementsByTagName("html")[0].classList.remove('is-scrolling');
  }, []);
  return null;
}
