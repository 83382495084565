'use client';

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, width = 20, sx, display = 'flex', ...other }, ref) => (
  <Box sx={{ minWidth: width, minHeight: width, display, alignItems: 'center' }}>
    <Box
      ref={ref}
      component={Icon}
      className="component-iconify"
      icon={icon}
      sx={{ width, minWidth: width, height: width, ...sx }}
      {...other}
    />
  </Box>
));

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  display: PropTypes.string,
  width: PropTypes.any,
};

export default Iconify;
